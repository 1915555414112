import {CreateProductionCommand} from "@/models/productions/CreateProductionCommand";
import {apiClient} from "@/modules/apiClient";
import {Production} from "@/models/productions/Production";
import {AxiosResponse} from "axios";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {AvatarViewModel} from "@/models/AvatarViewModel";
import {UpdateProductionCommand} from "@/models/productions/UpdateProductionCommand";
import {ExportProductionMeasurementsRequest} from "@/models/productions/ExportProductionMeasurementsRequest";
import {UserMeasurement} from "@/models/user/UserMeasurement";
import {ProductionOnboardingStatus} from "@/models/productions/ProductionOnboardingStatus";

export const ProductionService = {
    list(): Promise<AxiosResponse<Array<ProductionMembership>>> {
        return apiClient.get(`/productions`)
    },
    fetch(productionId: string): Promise<AxiosResponse<Production>> {
        return apiClient.get(`/productions/${productionId}`)
    },
    create(command: CreateProductionCommand, organizationId: string): Promise<AxiosResponse<Production>> {
        return apiClient.post(`/productions`, command)
    },
    update(productionId: string, command: UpdateProductionCommand): Promise<AxiosResponse> {
        return apiClient.put(`/productions/${productionId}`, command)
    },
    archive(productionId: string, workflow = "archive"): Promise<AxiosResponse> {
        return apiClient.post(`/productions/${productionId}/archive?workflow=${workflow}`)
    },
    delete(productionId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/productions/${productionId}/self`)
    },
    fetchOnboardStatus(productionId: string): Promise<AxiosResponse<ProductionOnboardingStatus>> {
        return apiClient.get(`/productions/${productionId}/onboarding-status`)
    },
    uploadPhoto(productionId: string, formData): Promise<AxiosResponse<AvatarViewModel>> {
        return apiClient.put(`/productions/${productionId}/photo`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    removePhoto(productionId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/productions/${productionId}/photo`)
    },
    exportProductionMeasurements(command: ExportProductionMeasurementsRequest): Promise<AxiosResponse> {
        return apiClient.get(`/measurements/export/${command.productionId}?format=${command.format}`, {
            responseType: 'blob',
            params: {groups: command.groups}
        })
    },
    listProductionMeasurements(productionId: string): Promise<AxiosResponse<Array<UserMeasurement>>> {
        return apiClient.get(`/measurements/production-list?productionId=${productionId}`)
    },
}
