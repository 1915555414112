import { appInsights, openReplayTracker } from "@/main";
import Bugsnag from "@bugsnag/js";
import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";
import { H } from "highlight.run";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { useUser } from "@/store/pinia/userStore";
import { Capacitor } from "@capacitor/core";
import Analytics from "@capacitor-community/appcenter-analytics";
// @ts-ignore
import { AnalyticsBrowser } from "@segment/analytics-next";
import { App } from "@capacitor/app";
import { getMembershipRole, MembershipRole } from "@/models/enums/Role";
import { EntityStatus } from "@/models/enums/EntityStatus";
import { MembershipStatus } from "@/models/enums/MembershipStatus";
import { ProductionMembership } from "@/models/productions/ProductionMembership";
import { OrganizationMembership } from "@/models/organizations/OrganizationMembership";
import { NativeAnalytics } from "@/modules/AnalyticsPlugin";
import { shutdown, trackEvent } from "@intercom/messenger-js-sdk";
import formbricks from "@formbricks/js";

export const segmentAnalytics = new AnalyticsBrowser();

export class WebAnalytics {
	private static dataLayerName: string = "dataLayer";
	private static identifyEventName: string = "identify";
	private static groupEventName: string = "group";
	private static logoutEventName: string = "logout";
	private static pageViewEventName: string = "pageCall";
	private static standardEventName: string = "customEvent";

	private static getHighestRole(): string {
		const user = useUser();

		let role = MembershipRole.Member;

		// Define a list of roles in priority order
		const rolesPriority = [
			MembershipRole.Owner,
			MembershipRole.Administrator,
			MembershipRole.Manager,
			MembershipRole.Member
		];

		// Function to check memberships for a higher role
		const checkForHigherRole = (memberships: ProductionMembership[] | OrganizationMembership[]) => {
			for (const priorityRole of rolesPriority) {
				if (memberships.some(
					m => m.entityStatus === EntityStatus.Active &&
						m.status === MembershipStatus.Active &&
						m.role === priorityRole
				)) {
					role = priorityRole;
					return true; // Exit once a higher role is found
				}
			}
			return false;
		};

		// Check productions first
		if (checkForHigherRole(user.productions)) {
			return getMembershipRole(role).name;
		}

		// If not found, check organizations
		checkForHigherRole(user.organizations);

		return getMembershipRole(role).name;
	}

	static identify(identity: AnalyticsIdentity) {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		segmentAnalytics.identify(identity.id, identity);
		WebAnalytics.pushToDataLayer(this.identifyEventName, {
			userId: identity.id,
			userObject: identity,
		});
		appInsights.setAuthenticatedUserContext(identity.id, undefined, true);
		Bugsnag.setUser(identity.id, identity.email, `${identity.firstName} ${identity.lastName}`);
		Sentry.setUser({
			id: identity.id,
			email: identity.email,
			username: `${identity.firstName} ${identity.lastName}`,
		});
		Sentry.setTag("role", this.getHighestRole());
		posthog.identify(identity.id, {
			...identity,
			role: this.getHighestRole()
		});
		openReplayTracker.setUserID(identity.id);
		openReplayTracker.setMetadata('Role', this.getHighestRole());
		H.identify(identity.id, { highlightDisplayName: identity.name, ...identity });
		FirebaseAnalytics.setUserId({
			userId: identity.id,
		});
		if (identity.email.indexOf("@stagehub.com") === -1) {
			WebAnalytics.initalizeFreshchat(identity);
			WebAnalytics.initFreshsales(identity);
		}
		if (!!window.newrelic) {
			window.newrelic.setUserId(identity.id);
			if (Capacitor.isNativePlatform()) {
				App.getInfo().then(appInfo => {
					window.newrelic.setApplicationVersion(appInfo.version);
				});
			}
		}
		if(Capacitor.isNativePlatform()){
			NativeAnalytics.identify({
				userId: identity.id,
				traits: identity,
			})
		}

		try {
			if (typeof window !== "undefined") {
				formbricks.init({
					environmentId: process.env.VUE_APP_FORMBRICKS_ENV_ID,
					apiHost: process.env.VUE_APP_FORMBRICKS_API_HOST,
					userId: identity.id,
					attributes: {
						Role: this.getHighestRole(),
						email: identity.email,
						"First Name": identity.firstName,
						"Last Name": identity.lastName,
						"Industry": identity.industry,
						"Initial Persona": identity.initialPersona,
						"User Type": identity.userType
					}
				});
			}
		}catch(err){}
	}

	static identifyOrganization(identity: OrganizationIdentity) {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		segmentAnalytics.group(identity.organizationId, identity);
		WebAnalytics.pushToDataLayer(this.groupEventName, {
			groupId: identity.organizationId,
			groupObject: identity,
		});
		posthog.group("organization", identity.organizationId, identity);
	}

	static identifyProduction(identity: ProductionIdentity) {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		segmentAnalytics.group(identity.productionId, identity);
		WebAnalytics.pushToDataLayer(this.groupEventName, {
			groupId: identity.productionId,
			groupObject: identity,
		});
		posthog.group("production", identity.productionId, identity);
	}

	static logout() {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		segmentAnalytics.reset();
		WebAnalytics.pushToDataLayer(this.logoutEventName);
		appInsights.clearAuthenticatedUserContext();
		posthog.reset();
		Sentry.setUser(null);
		shutdown(); //Intercom
		formbricks.logout();
	}

	static view(page: PageSpec) {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		page.platform = Capacitor.getPlatform();
		page.application = "Backstage";
		segmentAnalytics.page(page.pageName, page);
		WebAnalytics.pushToDataLayer(this.pageViewEventName, page);
		appInsights.trackPageView({
			name: page.pageName,
			refUri: `${document.location.protocol}://${document.location.host}${page.fromPath}`,
			uri: `${document.location.protocol}://${document.location.host}${page.path}`,
		});
		posthog.capture("$pageview");
		openReplayTracker.event("view", page.pageName);
		FirebaseAnalytics.setScreenName({
			screenName: page.pageName,
			nameOverride: page.pageName,
		});
	}

	static track(event: AnalyticsEvent, payload: DataLayerSpec) {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		segmentAnalytics.track(event, { ...payload, application: "Backstage", platform: Capacitor.getPlatform() });
		//todo: always push the page name
		WebAnalytics.pushToDataLayer(this.standardEventName, {
			event: "customEvent",
			eventName: event,
			eventObject: payload,
		});
		posthog.capture(event, payload);
		openReplayTracker.event(event, payload);
		appInsights.trackEvent({ name: event, properties: payload });
		H.track(event, { ...payload });
		if (Capacitor.isNativePlatform()) {
			Analytics.trackEvent({ name: event });
		}
	}

	static trackFlexible(event: string, payload: any) {
		if (process.env.VUE_APP_ANALYTICS === "disabled") {
			return;
		}
		payload.platform = Capacitor.getPlatform();
		payload.application = "Backstage";
		segmentAnalytics.track(event, payload);
		WebAnalytics.pushToDataLayer(this.standardEventName, {
			event: "customEvent",
			eventName: event,
			eventObject: payload,
		});
		posthog.capture(event, payload);
		openReplayTracker.event(event, payload);
		appInsights.trackEvent({ name: event, properties: payload });
		if (Capacitor.isNativePlatform()) {
			Analytics.trackEvent({ name: event, properties: payload });
		}
		H.track(event, { ...payload });
		WebAnalytics.trackFreshsales(event, payload);
		trackEvent(event);
		try {
			formbricks.track(event, payload);
		}catch(err){}
	}

	/* Helpers */
	/**
	 * Push Payload to DataLayer
	 * @description Will prepend the trigger event to the data layer payload, and push to the window object specified in {dataLayerName}
	 * @param event Trigger event in tag manager
	 * @param payload The object to send
	 * @private
	 */
	private static pushToDataLayer(event: string, payload?: object) {
		if (process.env.VUE_APP_SH_ENV === "disabled") {
			return;
		}
		const payloadToSend = payload ? payload : {};
		payloadToSend["event"] = event;
		if (typeof window[this.dataLayerName] !== "undefined") {
			window[this.dataLayerName].push(payloadToSend);
		}
	}

	private static initFreshsales(identity: AnalyticsIdentity) {
		if (process.env.VUE_APP_SH_ENV === "disabled") {
			return;
		}
		try {
			if (typeof window.fwcrm !== "undefined") {
				window.fwcrm.identify(identity.id, {
					"First name": identity.firstName,
					"Last name": identity.lastName,
					"Email": identity.email,
				});
			}
		} catch (e) {
		}
	}

	private static trackFreshsales(eventName: string, payload?: any) {
		if (process.env.VUE_APP_SH_ENV === "disabled") {
			return;
		}
		try {
			const user = useUser();
			const email = user.profile.emailAddresses.find(x => x.isPrimary);
			if (!!payload) {
				payload.platform = Capacitor.getPlatform();
				payload.application = "Backstage";
			}
			if (typeof window.FM !== "undefined") {
				window.FM.trackCustomEvent(eventName, { email: email?.email, ...payload });
			} else if (typeof window.fwcrm !== "undefined") {
				window.fwcrm.trackCustomEvent(eventName, { email: email?.email, ...payload });
			}
		} catch (e) {
			console.error("Error tracking event in Freshsales", e, eventName, payload);
		}
	}

	static initChangelogWidget() {
		try {
			(function(e, t) {
				const a = "featurebase-sdk";

				function n() {
					if (!t.getElementById(a)) {
						var e = t.createElement("script");
						(e.id = a),
							(e.src = "https://do.featurebase.app/js/sdk.js"),
							// @ts-ignore
							t.getElementsByTagName("script")[0].parentNode.insertBefore(e, t.getElementsByTagName("script")[0]);
					}
				}

				"function" != typeof e.Featurebase &&
				(e.Featurebase = function() {
					(e.Featurebase.q = e.Featurebase.q || []).push(arguments);
				}),
					"complete" === t.readyState || "interactive" === t.readyState
						? n()
						: t.addEventListener("DOMContentLoaded", n);
			})(window, document);
			window.Featurebase("initialize_changelog_widget", {
				organization: "stagehub",
				placement: "left",
				theme: "dark",
			});
		} catch (e) {
		}
	}

	/***
	 * Initialize Freshchat
	 * @param identity
	 * @private
	 * @description Will initialize freshchat with the user's identity
	 * @see https://developers.freshchat.com/web-sdk/#initialize
	 * @see https://developers.freshchat.com/web-sdk/#identify
	 */
	private static initalizeFreshchat(identity: AnalyticsIdentity) {
		try {
			// @ts-ignore
			window.initFreshChat = function() {
				// @ts-ignore
				window.fcWidget.init({
					token: "927a7999-6b6d-46b1-bbbd-823fa9fcec81",
					host: "https://wchat.freshchat.com",
					externalId: identity.id,
					firstName: identity.firstName,
					lastName: identity.lastName,
					email: identity.email,
					pronouns: identity.pronouns,
					avatar: identity.avatar,
					config: {
						headerProperty: {
							hideChatButton: true,
						},
						cssNames: {
							widget: "fc_frame",
							open: "fc_open",
							expanded: "fc_expanded",
						},
					},
				});
			};
			//@ts-ignore
			window.initialize = function(i, t) {
				//@ts-ignore
				let e;

				// @ts-ignore
				i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e));
			};
			// @ts-ignore
			window.initialize(document, "Freshdesk Messaging-js-sdk");
			// eslint-disable-next-line no-empty
		} catch (e) {
		}
	}

}

/***
 * Analytics Identity
 * @description This is the identity object that will be sent to all analytics platforms
 * @see https://segment.com/docs/connections/spec/identify/
 */
interface AnalyticsIdentity {
	id: string;
	age: number;
	avatar: string;
	$avatar: string;
	birthday: string;
	createdAt: string;
	email: string;
	firstName: string;
	lastName: string;
	name: string;
	gender: string;
	title: string;
	pronouns: string;
	StageCode: string;
	industry: string;
	initialPersona: string;
	userType: string;
}

interface PageSpec {
	pageName: string
	path: string
	url: string
	fromPath: string,
	friendlyPath: string,
	friendlyUrl: string,
	platform: string,
	application: string
}

interface DataLayerSpec {
	eventName: AnalyticsEvent;
	wizardName?: AnalyticsWizard;
	wizardStep?: string;
	entity?: AnalyticsEntity;
	action?: AnalyticsAction;
	profileEntity?: AnalyticsProfileEntity;
	//eventSource?: AnalyticsEventSource
	//linkName?: string
}

export enum AnalyticsEvent {
	Wizard = "Wizard",
	ManageEntity = "Manage Entity",
	ManageProfile = "Manage Profile",
	Login = "Login",
	Logout = "Logout",
	LinkClick = "Link Click"
}

export enum AnalyticsEntity {
	Communication = "Communication",
	Announcement = "Announcement",
	Notification = "Notification",
	Event = "Event",
	Call = "Call",
	Profile = "User Profile",
	OrgProfile = "Organization Profile",
	ProdProfile = "Production Profile",
	Report = "Report",
}

export enum AnalyticsProfileEntity {
	Venue = "Venue",
	VenueSpace = "Venue Space",
	Person = "Person",
	Group = "Group",
	Conflict = "Conflict",
	CallType = "CallType",
	ReportTemplate = "Report Template",
	EmailAddress = "Email Address",
	MailingAddress = "Mailing Address",
	PhoneNumber = "Phone Number",
	EmergencyContact = "Emergency Contact",
	MedicalNote = "Medical Note",
	PrivacySetting = "Privacy Setting",
	Password = "Password",
	GeneralSetting = "General Setting",
	Photo = "Photo"
}

export enum AnalyticsAction {
	Create = "Create",
	Read = "Read",
	Update = "Update",
	Delete = "Delete",
	Start = "Start",
	Complete = "Complete",
	Publish = "Publish",
	Verify = "Verify"
}

export enum AnalyticsWizard {
	SignUp = "Sign Up",
	ForgotPassword = "Forgot Password",
	CreateProfile = "Create Profile",
	CreateOrganization = "Create Organization",
	CreateProduction = "Create Production"
}

export enum AnalyticsEventSource {
	QuickActions = "Quick Actions",
	Dashboard = "Dashboard"
}

export interface OrganizationIdentity {
	organizationId: string;
	name: string;
	$name: string;
	$avatar: string;
	//createdAt: string
	email: string;
	phone: string;
	//employees: number
	type: string; //Industry
	website: string;
	//productionCount: number
	StageCode: string;
	industry: string;
}

export interface ProductionIdentity {
	productionId: string;
	name: string;
	$name: string;
	$avatar: string;
	//createdAt: string
	//employees: number
	type: string;
	//website: string
	StageCode: string;
}
