import {apiClient} from "@/modules/apiClient";
import {Organization} from "@/models/organizations/Organization";
import {AxiosResponse} from "axios";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import {AvatarViewModel} from "@/models/AvatarViewModel";
import {UpsertOrganizationCommand} from "@/models/organizations/UpsertOrganizationCommand";
import {Production} from "@/models/productions/Production";
import {OrganizationOnboardingStatus} from "@/models/organizations/OrganizationOnboardingStatus";

export const OrganizationService = {
    list(): Promise<AxiosResponse<Array<OrganizationMembership>>> {
        return apiClient.get('/organizations')
    },
    fetch(organizationId: string): Promise<AxiosResponse<Organization>> {
        return apiClient.get(`/organizations/${organizationId}`)
    },
    listProductions(organizationId: string): Promise<AxiosResponse<Array<Production>>> {
        return apiClient.get(`/organizations/${organizationId}/productions`)
    },
    fetchOnboardStatus(organizationId: string): Promise<AxiosResponse<OrganizationOnboardingStatus>> {
        return apiClient.get(`/organizations/${organizationId}/onboarding-status`)
    },
    create(command: UpsertOrganizationCommand): Promise<AxiosResponse<Organization>> {
        return apiClient.post(`/organizations`, command)
    },
    update(organizationId: string, command: UpsertOrganizationCommand): Promise<AxiosResponse> {
        return apiClient.put(`/organizations/${organizationId}`, command)
    },
    delete(organizationId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/organizations/${organizationId}`)
    },
    uploadPhoto(organizationId: string, formData): Promise<AxiosResponse<AvatarViewModel>> {
        return apiClient.put(`/organizations/${organizationId}/photo`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    removePhoto(organizationId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/organizations/${organizationId}/photo`)
    },
}
